import { Component } from 'react';
import Typewriter from 'typewriter-effect';
import {IntroText, IntroString} from './IntroText';


class Intro extends Component {
    constructor(params) {
        super();
        this.params = params;
    }

    componentDidMount() {
        this.params.setIntroRead(true);   
    }

    render() {
        if (this.params.introRead) {
            return <IntroText></IntroText>
        } else {
            return <Typewriter
                onInit={(typewriter) => {
                    typewriter
                        .changeDelay(1)
                        .typeString(IntroString)
                        .start();
                }}
            />;
        }
    }
}

export default Intro;