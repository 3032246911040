import { Component } from 'react';
import Typewriter from 'typewriter-effect';
import { ContactText, ContactString } from './ContactText';

class Contact extends Component {
    constructor(params) {
        super();
        this.params = params;
    }

    componentDidMount() {
        this.params.setContactRead(true);
    }

    render() {
        if (this.params.contactRead) {
            return <ContactText></ContactText>
        } else {
            return <Typewriter
                onInit={(typewriter) => {
                    typewriter
                        .changeDelay(1)
                        .typeString(ContactString)
                        .start();
                }}
            />;
        }
    }
}

export default Contact;