import { Component } from 'react';
const ContactString = `<span style="color: lightgray;">1: </span>
<span style="color: white;">Please contact me on,</span>
<br><span style="color: lightgray;">2: </span>
<span style="color: white;">` +
`<a class="mylink text-decoration-none" href="https://www.linkedin.com/in/bits-to-breath/">LinkedIn </a>/in/bits-to-breath.</span>`

class ContactText extends Component {
    render() {
        return (
            <div>
                <span style={{color: 'lightgray'}}>1: </span>
                <span style={{color: 'white'}}>Please contact me on,</span>
                <br/><span style={{color: 'lightgray'}}>2: </span>
                <span style={{color: 'white'}}><a className="mylink text-decoration-none" href="https://www.linkedin.com/in/bits-to-breath/">LinkedIn </a>/in/bits-to-breath.</span>
                <span className="my__cursor">|</span>
            </div>
        );
    }
}

export { ContactText, ContactString };