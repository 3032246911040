import { PageNames } from '../AppConsts';
import { Component } from 'react';

const IntroString = `
<span style="color: lightgray;">1: </span>
            <span style="color: white;">Hello, I'm Austin.</span>
            <br/><span style="color: lightgray;">2: </span>
            <span style="color: white;">I am pursuing and studying:</span>
            <br/><span style="color: lightgray;">3: </span>
            <span style="color: white;">Software Engineering,</span>
            <br/><span style="color: lightgray;">4: </span>
            <span style="color: white;">Cyber Security,</span>
            <br/><span style="color: lightgray;">5: </span>
            <span style="color: white;">And Cloud Architecture.</span>
            <br/><span style="color: lightgray;">6: </span>
            <span style="color: white;">Click <strong>` + PageNames[1] + `</strong> for more.</span>`

class IntroText extends Component {
    render() {
        return (
            <div>
                <span style={{ color: 'lightgray' }}>1: </span>
                <span style={{ color: 'white' }}>Hello, I'm Austin.</span>
                <br /><span style={{ color: 'lightgray' }}>2: </span>
                <span style={{ color: 'white' }}>I am pursuing and studying:</span>
                <br /><span style={{ color: 'lightgray' }}>3: </span>
                <span style={{ color: 'white' }}>Software Engineering,</span>
                <br /><span style={{ color: 'lightgray' }}>4: </span>
                <span style={{ color: 'white' }}>Cyber Security,</span>
                <br /><span style={{ color: 'lightgray' }}>5: </span>
                <span style={{ color: 'white' }}>And Cloud Architecture.</span>
                <br /><span style={{ color: 'lightgray' }}>6: </span>
                <span style={{ color: 'white' }}>Click <strong>{PageNames[1]} </strong> for more.</span>
                <span className="my__cursor">|</span>
            </div>
        );
    }
}

export { IntroText, IntroString };