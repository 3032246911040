import { Component } from 'react';

const WorkString = `<span style="color: lightgray;">1: </span>
<span style="color: white;">Click or Tap a pic twice for info. </span>`

class WorkText extends Component {
    render() {
        return (
            <div>
                <span style={{color: 'lightgray'}}>1: </span>
                <span style={{color: 'white'}}>Click or Tap a pic twice for info. </span>
                <span className="my__cursor">|</span>
            </div>
        );
    }
}

export { WorkText, WorkString };