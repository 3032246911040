import { Component } from 'react';
import Typewriter from 'typewriter-effect';
import { AboutText, AboutString } from './AboutText';

class About extends Component {
    constructor(params) {
        super();
        this.params = params;
    }

    componentDidMount() {
        this.params.setAboutRead(true);
    }

    render() {
        if (this.params.aboutRead) {
            return <AboutText></AboutText>
        } else {
            return <Typewriter
                onInit={(typewriter) => {
                    typewriter
                        .changeDelay(1)
                        .typeString(AboutString)
                        .start();
                }}
            />;
        }
    }
}

export default About;